import { EZW_APP_STORE_URL, BR_APP_STORE_URL } from '../constants'
import EzwAppIcon from "../images/EzwAppIcon.png";
import BrAppIcon from "../images/BrAppIcon.png";

//*********************************************************************/

export const Footer = () =>  {

  //*********************************************************************/

  return (
    <div className="RowFooter" >
      <div className="Heading">Download Now</div>
      <div id="AppLogosLinks">
        <div>
          <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
            <img className="AppIcon" src={EzwAppIcon} alt="EasyWellness" />
          </a>
        </div>
        <div>
          <a href={BR_APP_STORE_URL} target="_blank" rel="noreferrer">
            <img className="AppIcon" src={BrAppIcon} alt="Breethr" />
          </a>
        </div>
      </div>
      <div className="Email" style={{ marginTop: 40 }}>Contact us at <a href="mailto:info@littlebylittlelabs.com">info@littlebylittlelabs.com.</a></div>
      
      <div id="RowCopyright">
        <div id="Copyright">&#169; Copyright 2021, Little By Little Labs, LLC</div>
      </div>
    </div>
  )

  //*********************************************************************/
}

