import { Link } from 'react-router-dom'
import { blogUrlParam } from "../utilities"

//*********************************************************************/

export const BlogCard = ({ blog }) =>  {
  
  const pubDate = new Date(blog.pubDate.replace(" ","T")).toDateString()

  //*********************************************************************/

  return (

    <Link to={'/blogs/'+ blogUrlParam(blog.title)} >
      <div className="BlogCard">
        <img src={blog.thumbnail} alt="thumb"/>
        <div style={{ margin: "8px 20px 0 20px" }}>
          <div  className="BlogTitle">{blog.title}</div>
          <div className="TextSmall" style={{ margin: "10px 0" }}>{blog.content.match("<h4>(.*?)</h4>")[1]}</div>
          <div style={{ margin: "20px 0", color: "gray", fontSize: 14 }}>{pubDate}</div>
        </div>
      </div>
    </Link>
  )

  //*********************************************************************/
}