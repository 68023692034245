
import { MEDIUM_JSON } from './constants'

//*********************************************************************/

export const fetchBlogData = async () => {
  
  try {
      
    const response = await fetch(MEDIUM_JSON);
      
    const json = await response.json()
      
    return json

  } catch (error) {
      
    console.log("error", error)

    return {}
  }
}

//*********************************************************************/
