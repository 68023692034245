//***************************************************************

export const BR_APP_STORE_URL = "https://apps.apple.com/us/app/breethr-breathing-exercises/id1565078313"
export const EZW_APP_STORE_URL = "https://apps.apple.com/us/app/easywellness/id1579425482"

export const EZW_WWW_URL = "https://www.easywellness.app/"
export const BR_WWW_URL = "https://www.breethr.app/"

export const EZW_INSTAGRAM_URL = "https://www.instagram.com/easywellness.app/"
export const BR_INSTAGRAM_URL = "https://www.instagram.com/breethr.app/"

export const EZW_FACEBOOK_URL = "https://www.facebook.com/easywellnessapp/"
export const BR_FACEBOOK_URL = "https://www.facebook.com/breethr/"

export const LBL_BLOG_URL = "https://medium.com/little-by-little-labs"

export const MEDIUM_RSS_FEED = "https://medium.com/feed/little-by-little-labs"
export const MEDIUM_JSON = `https://api.rss2json.com/v1/api.json?rss_url=${MEDIUM_RSS_FEED}`


//***************************************************************
