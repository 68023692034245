import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom';
import { fetchBlogData } from '../actions'
import { blogUrlParam } from "../utilities"
import LblLogo from "../images/LblLogo.png";
import { Footer } from "./Footer"

//*********************************************************************/

export default function Blog() {
  
  //*********************************************************************/

  const [blog, setBlog] = useState({})
  const [loading, setLoading] = useState(true)
  const params = useParams()

  //*********************************************************************/

  useEffect(() => {

    fetchBlogData().then((feedJson) => {

      feedJson.items.forEach(blog => {
        
        if (blogUrlParam(blog.title) === params.title) {

          setBlog(blog)

          return
        }
      })
      
      setLoading(false)
    })

  }, [])

  //*********************************************************************/

  return (
    <div style={{ marginTop: 20, textAlign: "" }}>
      <div id="NavContainer">
        <a href="/"><img className="AppLogo" src={LblLogo} alt="LittleByLittleLabs" /></a>
      </div>
        
      <div className="BlogHeader">{blog.title}</div>

      {!loading && blog.content && <div className="Blog" dangerouslySetInnerHTML={{ __html: blog.content.replace(/<figcaption>.*?<\/figcaption>/ig,'') }}></div>}
      <Footer/>
    </div>
  )

  //*********************************************************************/

}