import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { hydrate, render } from "react-dom"
import Home from "./components/Home"
import Blog from "./components/Blog"
import "./styles/styles.css"

export default function App() {

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} exact />
          <Route path="/blogs/:title" element={<Blog/>} />
        </Routes>
      </BrowserRouter>
  )
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}