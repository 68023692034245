import { useEffect, useRef, useState } from "react";
import { BR_APP_STORE_URL, EZW_APP_STORE_URL, EZW_WWW_URL, BR_WWW_URL, EZW_INSTAGRAM_URL, BR_INSTAGRAM_URL, EZW_FACEBOOK_URL, BR_FACEBOOK_URL } from '../constants'
import LblLogo from "../images/LblLogo.png";
import SlideInSection from "./SlideInSection"
import TextTransition, { presets } from "react-text-transition";

import EzwAppIcon from "../images/EzwAppIcon.png";
import EzwLogo from "../images/EzwLogo.png";
import EzwMockup from "../images/EzwMockup.png";
import BrAppIcon from "../images/BrAppIcon.png";
import BrMockup from "../images/BrMockup.png";
import BrLogo from "../images/BrLogo.png";

import AppStoreButton from "../images/AppStoreButton.png";
import FbIcon from "../images/FbIcon.png";
import IgIcon from "../images/IgIcon.png";
import WebIcon from "../images/WebIcon.png";
import Maxine from "../images/Maxine.png";

import { fetchBlogData } from '../actions'
import { BlogCard } from "./BlogCard"
import { Footer } from "./Footer"

//*********************************************************************/

export default function Home() {
  
  //*********************************************************************/

  const meetTheAppsRef = useRef(null)
  const ourValuesRef = useRef(null)
  const aboutUsRef = useRef(null)
  const blogRef = useRef(null)

  const [index, setIndex] = useState(0)
  const [blogFeed, setBlogFeed] = useState({})
  const [loadingBlogs, setLoadingBlogs] = useState(true)

  const BENEFITS = [
    "health.",
    "happiness.",
    "mind.",
    "body.",
    "spirit."
  ];

  //*********************************************************************/

  useEffect(() => {
    
    const intervalId = setInterval(() => {
      
      setIndex(index => index + 1)
    
    }, 3000);

    return () => clearTimeout(intervalId);
  });

  //*********************************************************************/

  useEffect(() => {

    fetchBlogData().then((json) => {

      setBlogFeed(json)

      setLoadingBlogs(false)
    })

  }, [])

  //*********************************************************************/

  return (
    <div id="App">

      {/**************************************************************/}

      <div id="NavContainer">
        <div id="Nav">
          <img className="LblLogo" src={LblLogo} alt="LBL" />
          <div id="NavLinks">
            <div onClick={() => window.scrollTo({ top: meetTheAppsRef.current.offsetTop - 30 })}>Meet The Apps</div>
            <div onClick={() => window.scrollTo({ top: ourValuesRef.current.offsetTop - 30 })}>Our Values</div>
            <div onClick={() => window.scrollTo({ top: blogRef.current.offsetTop - 30 })}>Blog</div>
            {/* <div><a href={LBL_BLOG_URL} target="_blank" rel="noreferrer">Blog</a></div> */}
          </div>
        </div>
      </div>

      {/**************************************************************/}

      <div id="RowMain">
        <div id="RowMainLeft">
          <div className="TextLarge" style={{ marginBottom: 30 }}>Apps that help you achieve small, daily victories for your {" "}    
          <TextTransition inline={true} style={{ color: "#57B9BD", width: 100 }}text={ BENEFITS[index % BENEFITS.length] } springConfig={ presets.gentle } />
          </div>
          <div className="TextSmall">Check out our apps on the App Store:</div>
          <div id="AppLogosLinks">
            <div>
              <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
                <img className="AppIcon" src={EzwAppIcon} alt="EasyWellness" />
              </a>
            </div>
            <div>
              <a href={BR_APP_STORE_URL} target="_blank" rel="noreferrer">
                <img className="AppIcon" src={BrAppIcon} alt="Breethr" />
              </a>
            </div>
          </div>
        </div>
        <div id="RowMainRight"></div>
      </div>

      {/**************************************************************/}

      <div id="RowEzw" ref={meetTheAppsRef}>
        <div className="AppRow EzwRow">
          <SlideInSection fromLeft={true}><img className="Mockup" src={EzwMockup} alt=""/></SlideInSection>
          <div className="AppInfo">
            <img className="AppLogo" src={EzwLogo} alt="EasyWellness" />
            <div className="TextMedium">Build better habits with EasyWellness foundational health programs.</div>
              <div className="TextSmall">Stretch. Breathe. Move. Hydrate.<br/>
                Start your wellness journey today. EasyWellness will be
                your guide. Our programs were made for all body types
                and endurance levels. Our features are built to help you
                stay on track - even when you're busy or lack motivation!
              </div>
            <div className="SocialLinks">
              <div>
                <a href={EZW_APP_STORE_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={AppStoreButton} alt="EasyWellness App Store" />
                </a>
              </div>
              <div>
                <a href={EZW_WWW_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={WebIcon} alt="EasyWellness WWW" />
                </a>
              </div>
              <div>
                <a href={EZW_INSTAGRAM_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={IgIcon} alt="EasyWellness Instagram" />
                </a>
              </div>
              <div>
                <a href={EZW_FACEBOOK_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={FbIcon} alt="EasyWellness Facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**************************************************************/}

      <div id="RowBr">
        <div className="AppRow BrRow">
          <div className="AppInfo">
            <img className="AppLogo BrLogo" src={BrLogo} alt="EasyWellness" />
            <div className="TextMedium">From ancient practice to modern science. Learn to use the power of your breath.</div>
            <div className="TextSmall">Breethr offers quick, easy breathing exercises that you can do practically anytime and anywhere. Breathing exercises are the easiest way to re-center when life seems unbalanced. Use Breethr to focus, energize, relax, sleep better and improve lung health.</div>
            <div className="SocialLinks">
              <div>
                <a href={BR_APP_STORE_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={AppStoreButton} alt="Breethr App Store" />
                </a>
              </div>
              <div>
                <a href={BR_WWW_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={WebIcon} alt="Breethr WWW" />
                </a>
              </div>
              <div>
                <a href={BR_INSTAGRAM_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={IgIcon} alt="Breethr Instagram" />
                </a>
              </div>
              <div>
                <a href={BR_FACEBOOK_URL} target="_blank" rel="noreferrer">
                  <img className="SocialIcon" src={FbIcon} alt="Breethr Facebook" />
                </a>
              </div>
            </div>
          </div>
          <SlideInSection fromLeft={false}><img className="Mockup" src={BrMockup} alt=""/></SlideInSection>
        </div>
      </div>

      {/**************************************************************/}

      <div id="RowValues" ref={ourValuesRef}>
        <div className="Heading">Our Values</div>
        <div className="Values">
          <div className="ValueInfo">
            <div className="TextMedium" style={{ marginBottom: 22 }}>Inclusivity and Accessibility</div>
            <div className="TextSmall">
            At Little By Little Labs, we aim to create apps that are as inclusive and accessible as possible. Our apps contain flexible programs that can be customized to fit our users' needs. We also build features to allow for accessibility. Our community is stronger when we strive to serve as many people as we can.
            </div>
          </div>
          <div className="ValueInfo">
            <div className="TextMedium" style={{ marginBottom: 22 }}>Celebrate Small Victories</div>
            <div className="TextSmall">
            Our philosophy is in our name. We believe in celebrating the small victories so that we can better enjoy the road to reaching our goals. Taking small steps is the key to long term progress in both physical and mental health.
            </div>
          </div>
          <div className="ValueInfo">
            <div className="TextMedium" style={{ marginBottom: 22 }}>Health and Happiness are Intertwined</div>
            <div className="TextSmall">
            In order to be our best selves, we have to prioritize our physical, mental and social health. Our apps take a holistic approach to health and happiness and aim to provide long term benefits rather than superficial "quick fixes". 

            </div>
          </div>
          <div className="ValueInfo">
            <div className="TextMedium" style={{ marginBottom: 22 }}>Creative Excellence</div>
            <div className="TextSmall">
            We pride ourselves in creating apps that are proven effective, visually stunning and technically innovative. Our users deserve a memorable and seamless journey with our apps.
            </div>
          </div>
        </div>
      </div>

      <div id="RowBlog" ref={blogRef}>
        <div style={{ marginBottom: 40 }} className="Heading">Articles{loadingBlogs}</div>
        <div className="BlogListGrid">
          {!loadingBlogs && blogFeed.items.map((item) => {
            return <BlogCard blog={item} />
          })}
        </div>
      </div>

      <Footer/>

      {/**************************************************************/}

      <div id="RowAbout" ref={aboutUsRef}>
        <div className="Heading">About Us</div>
        <div className="TextMedium" style={{ maxWidth: 600, textAlign: "center" }}>Little By Little Labs is a software development company based in Southern California.</div>
        
        <div id="AboutUsColumns">
          <div className="AboutColumn">
            <img className="AboutImage" src={Maxine} alt="" />
            <div className="TextMedium" style={{ fontSize: 24 }}>Varun Salvi</div>
            <div className="TextSmall">Co-Founder</div>
          </div>
          <div className="AboutColumn">
            <img className="AboutImage" src={Maxine} alt="" />
            <div className="TextMedium" style={{ fontSize: 24 }}>Daniela Hummel</div>
            <div className="TextSmall">Co-Founder</div>

          </div>
          <div className="AboutColumn">
            <img className="AboutImage" src={Maxine} alt="" />
            <div className="TextMedium" style={{ fontSize: 24 }}>Max</div>
            <div className="TextSmall">The Boss</div>
          </div>
        </div>
        <br/>
        <div className="TextSmall">
          We are husband and wife (and dachshund) team! Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum.<br/><br/>
        
          Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum.
        </div>
        <div className="Email" style={{ marginTop: 40 }}>Contact us at <a href="mailto:info@littlebylittlelabs.com">info@littlebylittlelabs.com.</a></div>
        
      </div>

      {/**************************************************************/}

    </div>
  )

  //*********************************************************************/
}